import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import EBookBox from "../components/eBookBox"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css"
import "./global.scss"
import Share from "../components/Share"
import Img from "gatsby-image"
import _ from "lodash"

const BlogPost = ({ data, pageContext }) => {
  const slugs = data.allSitePage.edges.map(({ node }) => node.path)
  const titles = data.allSitePage.edges.map(
    ({ node }) => {
      if (node.context === null ){
        return ""
      } else {
        return node.context.title
      }
    } 
  )
  //console.log('page titles', titles)
  const staticContent = pageContext.staticContent[0]
  const language = pageContext.language
  //console.log('post static:', staticContent)
  //console.log("bread slugs=", slugs)
  //console.log('crumbs=', pageContext.breadcrumb.crumbs)
  const crumbs = pageContext.breadcrumb.crumbs.map(crumb => {
    const i = slugs.indexOf(crumb.pathname+"/")
    //console.log("crumb pathname=", crumb.pathname, "i=", i)
    if (i !== -1) {
      //console.log("crumb bien trouvé dans slugs:", crumb, "->", titles[i])
      if (crumb.pathname === "/en" || crumb.pathname === "/fr" || crumb.pathname === "/nl") {
        return { ...crumb, pathname: crumb.pathname+"/", crumbLabel: staticContent.breadTitle }
      }
      return { ...crumb, pathname: crumb.pathname+"/", crumbLabel: titles[i] }
    }
    //console.log("crumb NON trouvé dans slugs:", crumb)
    return { ...crumb}
  })
  //console.log('crumbs after=', pageContext.breadcrumb.crumbs)
  const post = data.markdownRemark
  const {
    post: {
      frontmatter: { title },
      fields: { slug },
    },
    site: {
      siteMetadata: { url, twitterHandle },
    },
  } = data
  const postCat = post.frontmatter.category.fields.slug
  const allPostsButCurrent = pageContext.allPosts.filter(
    item => item.title !== post.frontmatter.title
  )
  const suggestedPosts = _.shuffle(
    allPostsButCurrent.filter(aPost => aPost.category.fields.slug === postCat)
  ).slice(0, 3)
  return (
    <Layout
      pageContext={pageContext}
      seotitle={post.frontmatter.title}
      description={post.frontmatter.description}
      keywords={post.frontmatter.keywords}
      image={pageContext.featuredImage.src}
      imageTwitter={pageContext.imageTwitter}
      Container="container is-post"
      SearchClass="search empty"
    >
      <Img
        fluid={pageContext.featuredImage}
        alt={post.frontmatter.title}
        className="featured-image"
      />
      <div>
        <div>
          <Share
            socialConfig={{
              twitterHandle,
              config: {
                url: `${url}${slug}`,
                title,
                image: pageContext.featuredImage.src,
              },
            }}
            quote={post.frontmatter.description}
          />
          <div className="post-header">
            <div className="breadcrumbs-handler">
              <Breadcrumb crumbs={crumbs} hiddenCrumbs={['/']} crumbSeparator=" • " />
            </div>
            <div className="column catDate">
              <p className="has-text-centered">
                <Link
                  className={
                    "mr-20 " + post.frontmatter.category.frontmatter.title
                  }
                  to={
                    "/" +
                    post.frontmatter.language +
                    post.frontmatter.category.fields.slug+ "/"
                  }
                >
                  {post.frontmatter.category.frontmatter.title}
                </Link>
                <span className="has-text-weight-light is-size-7 has-text-grey-light">
                  {post.frontmatter.date}
                </span>
              </p>
              <h1 className="has-text-weight-bold">{post.frontmatter.title}</h1>
            </div>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: pageContext.html }}
            />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box e-book">
              <EBookBox
                title={pageContext.ebookTitle}
                text={pageContext.ebookText}
                ebookDownload={pageContext.ebookDownload}
                textCTA={pageContext.TextCTA}
              />
            </div>
          </div>
        </div>
        <div className="has-text-centered">
          <div className="tags">
            <span className="icon is-medium">
              <i className="fas fa-tags"></i>
            </span>
            <p className="tags">{staticContent.tagsText}</p>
          </div>
          <ul className="tags-list">
            {post.frontmatter.tags.map((tag, idx) => {
              return (
                <li key={idx}>
                  <Link
                    to={"/" + post.frontmatter.language + tag.fields.slug + "/"}
                    rel="tag"
                  >
                    {tag.frontmatter.title}
                  </Link>
                </li>
              )
            })}
          </ul>
          <div className="image is-128x128 author-image">
            <Img
              placeholderClassName="is-rounded"
              fluid={pageContext.authorImage}
              alt={post.frontmatter.author.frontmatter.title}
            />
          </div>
          <p className="has-text-centered author-details">
            {staticContent.authorText}
            <br />
            <Link
              to={
                "/" +
                post.frontmatter.language +
                post.frontmatter.author.fields.slug + "/"
              }
            >
              {post.frontmatter.author.frontmatter.title}
            </Link>
          </p>
        </div>
      </div>
      <div className="has-text-centered sug-posts">
        <hr />
        <h2 className="title is-3">{staticContent.moreReadingText}</h2>
        <div className="columns">
          {suggestedPosts.map((sugpost, idx) => {
            const sugLocalDate = new Date(sugpost.date).toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric' })
            return (
            <div key={idx} className="column">
              <div className="card is-3">
                <div className="card-image">
                  <Link
                    to={"/" + post.frontmatter.language + sugpost.slug + "/"}
                    className="image"
                  >
                    <Img fluid={sugpost.featuredImage} alt={sugpost.title} />
                  </Link>
                </div>
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <div className="subtitle is-6 post-cat-link">
                        <Link
                          to={
                            "/" +
                            post.frontmatter.language +
                            sugpost.category.fields.slug + "/"
                          }
                        >
                          {sugpost.category.frontmatter.title}&nbsp;
                        </Link>
                        <p>{sugLocalDate}</p>
                      </div>
                      <h3 className="title is-5">
                        <Link
                          to={"/" + post.frontmatter.language + sugpost.slug + "/"}
                        >
                          {sugpost.title}
                        </Link>
                      </h3>
                      <p>{sugpost.excerpt}</p>
                    </div>
                  </div>
                  <p className="has-text-right read-more">
                    <Link to={"/" + post.frontmatter.language + sugpost.slug + "/"}>
                      <span>{staticContent.seeMore}</span>
                      <span className="read-more-arrow">
                        <i className="fas fa-long-arrow-alt-right"></i>
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )})}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query BlogPostBySlug($slug: String!, $language: String!) {
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    allSitePage {
      edges {
        node {
          path
          context {
            title
          }
        }
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug }}, frontmatter: {language: {eq: $language}}) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    markdownRemark(fields: { slug: { eq: $slug }}, frontmatter: {language: {eq: $language}}) {
      frontmatter {
        title
        language
        description
        keywords
        date(locale: $language, formatString: "Do MMMM YYYY")
        author {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
        category {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
        tags {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
